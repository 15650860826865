<template>
    <b-modal :id="PopupId ?? 'variableDetails'" v-b-modal.modal-multi-2 size="xxl" :title="$t('details') + ' - ' + Equipment + '.' + Group + '.' + Variable" :cancel-title="$t('Cancel')" :ok-title="$t('Ok')" @shown="onShown" @ok="onOk" @cancel="onCancel">
        <div v-if="details != null" class="prevent-select">
            <b-tabs pills card>
                <b-tab v-if="Group == 'ProdCom'" aria-activedescendant="" :title="$t('Formula')">
                    <CodeEditor ref="editor" :Formula="details.Formula" :propProject="Project" />
                </b-tab>
                <!-- <b-tab aria-activedescendant="" :title="$t('Events')"> </b-tab> -->
                <b-tab v-if="Group == 'ProdCom'" aria-activedescendant="" :title="$t('Monitor')">
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('variables.details.trendDelay')" label-for="trendDelay">
                                <b-form-input id="trendDelay" v-model="details.TrendDelay" type="number" min="0" max="65536" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-tab>
                <!-- Scaling tab -->
                <b-tab v-if="Group != 'ProdCom' && this.scalingPageVisible" aria-activedescendant="" :title="$t('Scaling')" :class="{ disabled: Readonly }">
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('variables.details.scaleType')">
                                <b-form-select id="scaleType" v-model="details.ScaleType" :options="scaleTypeOptions" />
                            </b-form-group>
                        </b-col>
                        <b-col v-if="details.ScaleType == 1">
                            <b-form-group :label="$t('variables.details.coefficient')">
                                <b-form-input
                                    id="coefficient"
                                    type="number"
                                    min="1"
                                    max="9999999"
                                    @change="
                                        (value) => {
                                            if (value == null || value == '') {
                                                this.details.Coefficient = 1;
                                            } else if (value > 9999999) {
                                                this.details.Coefficient = 9999999;
                                            } else if (value < 1) {
                                                this.details.Coefficient = 1;
                                            }
                                        }
                                    "
                                    v-model="details.Coefficient"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="details.ScaleType == 2">
                        <b-col>
                            <b-card :header="$t('variables.details.realValue')">
                                <b-row>
                                    <b-col>
                                        <b-form-group :label="$t('min')">
                                            <b-form-input
                                                id="minReal"
                                                min="-1999999999"
                                                max="1999999999"
                                                @change="
                                                    (value) => {
                                                        if (value == null || value == '') {
                                                            this.details.MinReal = 0;
                                                        } else if (value > 1999999999) {
                                                            this.details.MinReal = 1999999999;
                                                        } else if (value < -1999999999) {
                                                            this.details.MinReal = -1999999999;
                                                        }
                                                    }
                                                "
                                                type="number"
                                                v-model="details.MinReal"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group :label="$t('max')">
                                            <b-form-input
                                                id="maxReal"
                                                min="-1999999999"
                                                max="1999999999"
                                                @change="
                                                    (value) => {
                                                        if (value == null || value == '') {
                                                            this.details.MaxReal = 0;
                                                        } else if (value > 1999999999) {
                                                            this.details.MaxReal = 1999999999;
                                                        } else if (value < -1999999999) {
                                                            this.details.MaxReal = -1999999999;
                                                        }
                                                    }
                                                "
                                                type="number"
                                                v-model="details.MaxReal"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                        <b-col>
                            <b-card :header="$t('variables.details.scaledValue')">
                                <b-row>
                                    <b-col>
                                        <b-form-group :label="$t('min')">
                                            <b-form-input
                                                id="minScale"
                                                min="-1999999999"
                                                max="1999999999"
                                                @change="
                                                    (value) => {
                                                        if (value == null || value == '') {
                                                            this.details.MinScale = 0;
                                                        } else if (value > 1999999999) {
                                                            this.details.MinScale = 1999999999;
                                                        } else if (value < -1999999999) {
                                                            this.details.MinScale = -1999999999;
                                                        }
                                                    }
                                                "
                                                type="number"
                                                v-model="details.MinScale"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <b-form-group :label="$t('max')">
                                            <b-form-input
                                                id="maxScale"
                                                min="-1999999999"
                                                max="1999999999"
                                                @change="
                                                    (value) => {
                                                        if (value == null || value == '') {
                                                            this.details.MaxScale = 0;
                                                        } else if (value > 1999999999) {
                                                            this.details.MaxScale = 1999999999;
                                                        } else if (value < -1999999999) {
                                                            this.details.MaxScale = -1999999999;
                                                        }
                                                    }
                                                "
                                                type="number"
                                                v-model="details.MaxScale"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-tab>
                <!-- Events tab -->
                <b-tab aria-activedescendant="#title">
                    <template #title>
                        <span class="tabHeaderText">
                            {{ $t('Events') }}
                            <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="InvalidEventState" />
                        </span>
                    </template>
                    <b-row>
                        <b-col cols="6">
                            <b-form-select v-model="selectedEvent">
                                <b-form-select-option v-for="(event, index) in events" :key="event.value" :value="event.value">{{ event.text }} {{ eventInvalidState(index) ? '/!\\' : '' }}</b-form-select-option>
                            </b-form-select>
                        </b-col>
                    </b-row>

                    <b-row v-if="selectedEvent != null" style="margin-top: 10px" :class="{ disabled: Readonly }">
                        <b-col>
                            <b-checkbox v-model="events[selectedEvent].Use" style="margin-top: 25px">{{ $t('activate') }}</b-checkbox>
                        </b-col>
                        <b-col :class="{ disabled: !events[selectedEvent].Use }">
                            <b-form-group :label="$t('Description')">
                                <b-form-input type="text" v-model="events[selectedEvent].AliasCpt" />
                            </b-form-group>
                        </b-col>
                        <b-col :class="{ disabled: !events[selectedEvent].Use }">
                            <b-form-group :label="$t('Priority')">
                                <b-form-input
                                    type="number"
                                    min="0"
                                    max="10"
                                    @change="
                                        (value) => {
                                            if (value == null || value == '') {
                                                this.events[selectedEvent].Priorite = 0;
                                            } else if (value > 10) {
                                                this.events[selectedEvent].Priorite = 10;
                                            } else if (value < 0) {
                                                this.events[selectedEvent].Priorite = 0;
                                            }
                                        }
                                    "
                                    v-model="events[selectedEvent].Priorite"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col :class="{ disabled: !events[selectedEvent].Use }" v-if="Type != 'System.DateTime'">
                            <b-form-group :label="$t('Criteria')">
                                <b-form-select v-if="Type == 'System.Boolean'" v-model="events[selectedEvent].valeurComptage" :options="availableCriteriaBoolean" />
                                <b-form-select v-else-if="Type == 'System.String'" v-model="events[selectedEvent].CritereComparaison" :options="availableCriteriaComparisonsStr" />
                                <b-form-select v-else v-model="events[selectedEvent].CritereComparaison" :options="availableCriteriaComparisons" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="selectedEvent != null && Type != 'System.DateTime' /*&& Type != 'System.String'*/ && Type != 'System.Boolean'" :class="{ disabled: !events[selectedEvent].Use || Readonly }">
                        <b-col cols="3">
                            <b-form-group :label="$t('triggers.unitaryNotification.compareType')" label-for="SrcValCriteria">
                                <b-form-select v-model="events[selectedEvent].SrcValCriteria" :options="availableCompareTypes" />
                            </b-form-group>
                        </b-col>
                        <b-col v-if="events[selectedEvent].SrcValCriteria == 0" cols="3">
                            <b-form-group v-if="Type == 'System.String'" :label="$t('threshold')" label-for="criteriaValue">
                                <b-form-input 
                                    v-model="events[selectedEvent].ConstantValue"
                                    type="text"
                                />
                            </b-form-group>
                            <b-form-group v-else :label="$t('threshold')" label-for="criteriaValue">
                                <b-form-input
                                    min="-1000000000"
                                    max="1000000000"
                                    @change="
                                        (value) => {
                                            if (value == null || value == '') {
                                                this.events[selectedEvent].ConstantValue = 0;
                                            } else if (value > 1000000000) {
                                                this.events[selectedEvent].ConstantValue = 1000000000;
                                            } else if (value < -1000000000) {
                                                this.events[selectedEvent].ConstantValue = -1000000000;
                                            }
                                        }
                                    "
                                    v-model="events[selectedEvent].ConstantValue"
                                    type="number"
                                />
                            </b-form-group>
                        </b-col>
                        <!-- VariableGroup -->
                        <b-col v-if="events[selectedEvent].SrcValCriteria == 1" cols="3">
                            <b-form-group :label="$t('Group')" label-for="VariableGroup">
                                <b-form-select :state="VariableGroupState" v-model="events[selectedEvent].VariableGroup" :options="triggerGroups" @change="onChangeValueGroup" />
                            </b-form-group>
                        </b-col>
                        <!-- VariableItem -->
                        <b-col v-if="events[selectedEvent].SrcValCriteria == 1" cols="3">
                            <b-form-group :label="$t('Item')" label-for="VariableItem">
                                <b-form-select :state="VariableItemState" v-model="events[selectedEvent].VariableItem" :options="criteriaItems" />
                            </b-form-group>
                        </b-col>
                        <!-- Offset -->
                        <b-col v-if="events[selectedEvent].SrcValCriteria == 1 && Type != 'System.String'" cols="3">
                            <b-form-group :label="$t('Offset')" label-for="Offset">
                                <b-form-input
                                    min="-100000"
                                    max="100000"
                                    @change="
                                        (value) => {
                                            if (value == null || value == '') {
                                                this.events[selectedEvent].Offset = 0;
                                            } else if (value > 100000) {
                                                this.events[selectedEvent].Offset = 100000;
                                            } else if (value < -100000) {
                                                this.events[selectedEvent].Offset = -100000;
                                            }
                                        }
                                    "
                                    v-model="events[selectedEvent].Offset"
                                    type="number"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="selectedEvent != null" :class="{ disabled: !events[selectedEvent].Use || Readonly }">
                        <b-col>
                            <b-form-group :label="$t('variables.details.archiveEvent')">
                                <b-form-checkbox v-model="events[selectedEvent].ArchiveAppear">{{ $t('variables.details.archiveAppear') }}</b-form-checkbox>
                                <b-form-checkbox v-model="events[selectedEvent].ArchiveDisappear">{{ $t('variables.details.archiveDisappear') }}</b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-tab>
                <!-- Bounds tab -->
                <b-tab v-if="Group != 'ProdCom' && this.scalingPageVisible" aria-activedescendant="" :title="$t('Bound')" :class="{ disabled: Readonly }">
                    <b-row>
                        <b-col>
                            <b-form-checkbox id="bound" style="margin-top: 40px" v-model="details.Bound"> {{ $t('variables.details.boundVariable') }} </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group :label="$t('min')">
                                <b-form-input
                                    id="minBound"
                                    min="-1999999999"
                                    max="1999999999"
                                    @change="
                                        (value) => {
                                            if (this.details.MinBound >= this.details.MaxBound) {
                                                this.details.MaxBound = this.details.MinBound * 1 + 1;
                                            }
                                            if (value == null || value == '') {
                                                this.details.MinBound = 0;
                                            } else if (value > 1999999999) {
                                                this.details.MinBound = 1999999999;
                                            } else if (value < -1999999999) {
                                                this.details.MinBound = -1999999999;
                                            }
                                        }
                                    "
                                    v-model="details.MinBound"
                                    type="number"
                                    :class="{ disabled: !details.Bound }"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group :label="$t('max')">
                                <b-form-input
                                    id="maxBound"
                                    min="-1999999999"
                                    max="1999999999"
                                    @change="
                                        (value) => {
                                            if (this.details.MaxBound <= this.details.MinBound) {
                                                this.details.MinBound = this.details.MaxBound * 1 - 1;
                                            }
                                            if (value == null || value == '') {
                                                this.details.MaxBound = 1;
                                            } else if (value > 1999999999) {
                                                this.details.MaxBound = 1999999999;
                                            } else if (value < -1999999999) {
                                                this.details.MaxBound = -1999999999;
                                            }
                                        }
                                    "
                                    v-model="details.MaxBound"
                                    type="number"
                                    :class="{ disabled: !details.Bound }"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-tab>
                <!-- Norm tab -->
                <b-tab v-if="Group != 'ProdCom' && this.scalingPageVisible" aria-activedescendant="" :class="{ disabled: Readonly }">
                    <template #title>
                        <span class="tabHeaderText">
                            {{ $t('Norm') }}
                            <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="invalidNormState" />
                        </span>
                    </template>
                    <b-form-checkbox id="haveMax" v-model="details.MaxNorm"> {{ $t('max') }} </b-form-checkbox>
                    <div>
                        <b-row>
                            <b-col>
                                <VariableNorm :Title="$t('variables.details.alarm')" ref="maxAlarmForm" :Norm="details.MaxAlarm" :Readonly="Readonly || !details.MaxNorm" :GroupVarList="GroupVarList" />
                            </b-col>
                            <b-col>
                                <VariableNorm :Title="$t('variables.details.default')" ref="maxDefaultForm" :Norm="details.MaxDefault" :Readonly="Readonly || !details.MaxNorm" :GroupVarList="GroupVarList" />
                            </b-col>
                        </b-row>
                    </div>
                    <b-form-checkbox id="haveMin" v-model="details.MinNorm"> {{ $t('min') }} </b-form-checkbox>
                    <div>
                        <b-row>
                            <b-col>
                                <VariableNorm :Title="$t('variables.details.alarm')" ref="minAlarmForm" :Norm="details.MinAlarm" :Readonly="Readonly || !details.MinNorm" :GroupVarList="GroupVarList" />
                            </b-col>
                            <b-col>
                                <VariableNorm :Title="$t('variables.details.default')" ref="minDefaultForm" :Norm="details.MinDefault" :Readonly="Readonly || !details.MinNorm" :GroupVarList="GroupVarList" />
                            </b-col>
                        </b-row>
                    </div>
                </b-tab>
                <!-- Simulation tab -->
                <b-tab v-if="Group != 'ProdCom'" aria-activedescendant="" :title="$t('Simulation')" :class="{ disabled: Readonly }">
                    <b-row>
                        <b-col cols="4">
                            <b-form-checkbox id="enableSimulation" v-model="details.Simulation.Use">{{ $t('simulation.enable') }}</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row :class="{ disabled: !details.Simulation.Use }" style="margin-top: 5px">
                        <b-col cols="6">
                            <b-form-group :label="$t('simulation.function')">
                                <b-form-select id="simulationFunction" v-model="details.Simulation.FunctionType" :options="availableSimulationFunctions" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('simulation.period') + ' (ms)'">
                                <b-form-input
                                    id="simulationPeriod"
                                    min="100"
                                    max="600000"
                                    @change="
                                        (value) => {
                                            if (value == null || value == '') {
                                                this.details.Simulation.Period = 1000;
                                            } else if (value > 600000) {
                                                this.details.Simulation.Period = 600000;
                                            } else if (value < 100) {
                                                this.details.Simulation.Period = 100;
                                            }
                                        }
                                    "
                                    v-model="details.Simulation.Period"
                                    type="number"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <!-- TODO: simulation parameters -->
                    <div :class="{ disabled: !details.Simulation.Use }">
                        <Ramp v-if="details.Simulation.FunctionType == 0" :simulationDetails="details.SimulationDetails" />
                        <Random v-else-if="details.Simulation.FunctionType == 1" :simulationDetails="details.SimulationDetails" :type="Type" />
                        <Sequence v-else-if="details.Simulation.FunctionType == 2" :simulationDetails="details.SimulationDetails" :type="Type" />
                    </div>
                </b-tab>
                <!-- Misc tab -->
                <b-tab v-if="Group != 'ProdCom'" aria-activedescendant="" :title="$t('Misc')" :class="{ disabled: Readonly }">
                    <b-row>
                        <b-col>
                            <b-form-checkbox id="allowPublish" style="margin-top: 40px" v-model="details.AllowPublish">
                                {{ $t('variables.details.allowPublish') }}
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-checkbox id="monitor" style="margin-top: 40px" v-model="details.MonitorVariation"> {{ $t('variables.details.monitorVariation') }} </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-checkbox id="mirror" style="margin-top: 40px" v-model="details.ActivateMirror"> {{ $t('variables.details.activateMirror') }} </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row style="margin-top: 40px">
                        <b-col cols="6">
                            <b-form-group>
                                <b-row>
                                    <b-col>
                                        <b-form-checkbox id="monitorInactivity" v-model="details.MonitorInactivity">{{ $t('variables.details.monitorInactivity') + ' (s)' }}</b-form-checkbox>
                                    </b-col>
                                    <b-col>
                                        <b-form-input
                                            id="trendDelay"
                                            v-model="details.Inactive"
                                            type="number"
                                            min="0"
                                            max="65536"
                                            @change="
                                                (value) => {
                                                    if (value == null || value == '') {
                                                        this.details.Inactive = 0;
                                                    } else if (value > 65536) {
                                                        this.details.Inactive = 65536;
                                                    } else if (value < 0) {
                                                        this.details.Inactive = 0;
                                                    }
                                                }
                                            "
                                            :class="{ disabled: !details.MonitorInactivity }"
                                        />
                                    </b-col>
                                </b-row>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="4">
                            <b-form-group :label="$t('variables.details.trendDelay') + ' (s)'" label-for="trendDelay">
                                <b-form-input
                                    id="trendDelay"
                                    v-model="details.TrendDelay"
                                    type="number"
                                    min="0"
                                    max="65536"
                                    @change="
                                        (value) => {
                                            if (value == null || value == '') {
                                                this.details.TrendDelay = 60;
                                            } else if (value > 65536) {
                                                this.details.TrendDelay = 65536;
                                            } else if (value < 0) {
                                                this.details.TrendDelay = 0;
                                            }
                                        }
                                    "
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
        </div>
        <template #modal-footer>
            <b-button variant="danger" @click="onDeleteVariable" :class="{ disabled: updating }" v-if="!deleteDisabled">{{ $t('Delete') }}</b-button>
            <b-button variant="primary" @click="onOk" :class="{ disabled: updating || invalidState }">{{ $t('Ok') }}</b-button>
        </template>
    </b-modal>
</template>

<script>
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import VariableNorm from '@/components/configuration/Variables/VariableNorm.vue';
import Ramp from '@/components/configuration/Variables/Simulation/Ramp.vue';
import Random from '@/components/configuration/Variables/Simulation/Random.vue';
import Sequence from '@/components/configuration/Variables/Simulation/Sequence.vue';
import ToastAlert from '@/utils/ToastAlert';
import CodeEditor from '@/components/CodeEditor.vue';
import { custom } from 'devextreme/ui/dialog';
export default {
    name: 'VariableDetails',
    props: {
        Project: String,
        Equipment: String,
        Group: String,
        Variable: String,
        Readonly: Boolean,
        Type: String,
        GroupVarList: Array,
        PopupId: String,
        onChanges: Function,
    },
    components: {
        VariableNorm,
        Ramp,
        Random,
        Sequence,
        CodeEditor,
        custom,
    },
    watch: {
        selectedEvent: async function (val) {
            if (this.selectedEvent == null) return;
            await this.getGroupList();
            await this.getCriteriaValueItems();
        },
    },
    computed: {
        invalidNormState() {
            if (this.details) {
                var MaxNorm = this.details.MaxNorm;
                var MinNorm = this.details.MinNorm;
            }
            if (!MaxNorm && !MinNorm) return false;
            if (this.$refs.maxAlarmForm == null || this.$refs.maxDefaultForm == null || this.$refs.minAlarmForm == null || this.$refs.minDefaultForm == null) return false;
            return this.$refs.maxAlarmForm.invalidState || this.$refs.maxDefaultForm.invalidState || this.$refs.minAlarmForm.invalidState || this.$refs.minDefaultForm.invalidState;
        },
        VariableGroupState() {
            return this.events[this.selectedEvent].Use && this.events[this.selectedEvent].VariableGroup == null ? false : null;
        },
        VariableItemState() {
            return this.events[this.selectedEvent].Use && this.events[this.selectedEvent].VariableItem == null ? false : null;
        },
        InvalidEventState() {
            // Check all events
            for (let i = 0; i < this.events.length; i++) {
                // Check if event is used
                if (this.events[i].Use) {
                    if (this.events[i].SrcValCriteria == 1) {
                        // Check if event is valid
                        if (this.events[i].VariableGroup == null || this.events[i].VariableItem == null) {
                            return true;
                        }
                    }
                }
            }
        },
        invalidState() {
            return this.InvalidEventState || this.invalidNormState;
        },
        scalingPageVisible() {
            return this.Type != 'System.Boolean' && this.Type != 'System.String' && this.Type != 'System.DateTime';
        },
        deleteDisabled() {
            const defaultVars = ['_Address', '_Date', '_Hour', '_LastUpdate', '_MilliSec', '_Minute', '_Second', '_WatchDog'];
            return defaultVars.includes(this.Variable) || this.Group !== 'ProdCom';
        },
    },
    data() {
        return {
            details: null,
            scaleTypeOptions: [
                { value: 1, text: this.$t('variables.details.coefficient') },
                { value: 2, text: this.$t('variables.details.scale') },
            ],
            normOptions: [
                { value: true, text: this.$t('constant') },
                { value: false, text: this.$t('variable') },
            ],
            availableSimulationFunctions: [
                { value: 0, text: this.$t('simulation.function.ramp') },
                { value: 1, text: this.$t('simulation.function.random') },
                { value: 2, text: this.$t('simulation.function.sequence') },
            ],
            events: [
                { value: 0, text: this.$t('variables.details.event') + ' 1', Use: false, Priorite: 0, CritereComparaison: 0, valeurComptage: false, Offset: 0, ArchiveAppear: false, ArchiveDisappear: false, SrcValCriteria: 0, ConstantValue: 0, VariableGroup: null, VariableItem: null },
                { value: 1, text: this.$t('variables.details.event') + ' 2', Use: false, Priorite: 0, CritereComparaison: 0, valeurComptage: false, Offset: 0, ArchiveAppear: false, ArchiveDisappear: false, SrcValCriteria: 0, ConstantValue: 0, VariableGroup: null, VariableItem: null },
                { value: 2, text: this.$t('variables.details.event') + ' 3', Use: false, Priorite: 0, CritereComparaison: 0, valeurComptage: false, Offset: 0, ArchiveAppear: false, ArchiveDisappear: false, SrcValCriteria: 0, ConstantValue: 0, VariableGroup: null, VariableItem: null },
                { value: 3, text: this.$t('variables.details.event') + ' 4', Use: false, Priorite: 0, CritereComparaison: 0, valeurComptage: false, Offset: 0, ArchiveAppear: false, ArchiveDisappear: false, SrcValCriteria: 0, ConstantValue: 0, VariableGroup: null, VariableItem: null },
                { value: 4, text: this.$t('variables.details.event') + ' 5', Use: false, Priorite: 0, CritereComparaison: 0, valeurComptage: false, Offset: 0, ArchiveAppear: false, ArchiveDisappear: false, SrcValCriteria: 0, ConstantValue: 0, VariableGroup: null, VariableItem: null },
                { value: 5, text: this.$t('variables.details.event') + ' 6', Use: false, Priorite: 0, CritereComparaison: 0, valeurComptage: false, Offset: 0, ArchiveAppear: false, ArchiveDisappear: false, SrcValCriteria: 0, ConstantValue: 0, VariableGroup: null, VariableItem: null },
                { value: 6, text: this.$t('variables.details.event') + ' 7', Use: false, Priorite: 0, CritereComparaison: 0, valeurComptage: false, Offset: 0, ArchiveAppear: false, ArchiveDisappear: false, SrcValCriteria: 0, ConstantValue: 0, VariableGroup: null, VariableItem: null },
                { value: 7, text: this.$t('variables.details.event') + ' 8', Use: false, Priorite: 0, CritereComparaison: 0, valeurComptage: false, Offset: 0, ArchiveAppear: false, ArchiveDisappear: false, SrcValCriteria: 0, ConstantValue: 0, VariableGroup: null, VariableItem: null },
                { value: 8, text: this.$t('variables.details.event') + ' 9', Use: false, Priorite: 0, CritereComparaison: 0, valeurComptage: false, Offset: 0, ArchiveAppear: false, ArchiveDisappear: false, SrcValCriteria: 0, ConstantValue: 0, VariableGroup: null, VariableItem: null },
                { value: 9, text: this.$t('variables.details.event') + ' 10', Use: false, Priorite: 0, CritereComparaison: 0, valeurComptage: false, Offset: 0, ArchiveAppear: false, ArchiveDisappear: false, SrcValCriteria: 0, ConstantValue: 0, VariableGroup: null, VariableItem: null },
            ],
            selectedEvent: null,
            availableCriteriaComparisons: [
                { value: 0, text: '>' },
                { value: 1, text: '<' },
                { value: 2, text: '>=' },
                { value: 3, text: '<=' },
                { value: 4, text: '=' },
                { value: 5, text: '<>' },
            ],
            availableCriteriaComparisonsStr: [
                { value: 4, text: '=' },
                { value: 5, text: '<>' },
            ],
            availableCriteriaBoolean: [
                { value: true, text: this.$t('True') },
                { value: false, text: this.$t('False') },
            ],
            availableCompareTypes: [
                { value: 0, text: this.$t('triggers.unitaryNotification.compareType_0') },
                { value: 1, text: this.$t('triggers.unitaryNotification.compareType_1') },
            ],
            triggerGroups: [],
            criteriaItems: [],
            showModal: true,
            updating: false,
        };
    },
    async mounted() {
        await this.getGroupList();
    },
    methods: {
        eventInvalidState(eventIndex) {
            return this.events[eventIndex].Use && this.events[eventIndex].SrcValCriteria == 1 && (this.events[eventIndex].VariableGroup == null || this.events[eventIndex].VariableItem == null);
        },
        async onShown(e) {
            if (!this.$store.state.auth.user.permissions.PermissionRead) return;
            const result = await ProdComEquipmentService.getVariableDetails(this.Equipment, this.Project, this.Group, this.Variable);
            if (result.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'warning'));
                this.details = null;
            } else {
                this.details = result.ret;

                if (this.details == null) {
                    this.details = {
                        SimulationDetails: {},
                    };
                }
                if (this.details.SimulationDetails == null) this.details.SimulationDetails = {};
                this.events = [];
                this.selectedEvent = 0;
                if (this.details?.Event1 != null) this.events.push(this.details?.Event1);
                else
                    this.events.push({
                        value: 0,
                        text: this.$t('variables.details.event') + ' 1',
                        Use: false,
                        Priorite: 0,
                        CritereComparaison: 4,
                        valeurComptage: false,
                        Offset: 0,
                        ArchiveAppear: false,
                        ArchiveDisappear: false,
                        SrcValCriteria: 0,
                        ConstantValue: this.Type == "System.String" ? "" : 0,
                        VariableGroup: null,
                        VariableItem: null,
                    });
                if (this.details?.Event2 != null) this.events.push(this.details?.Event2);
                else
                    this.events.push({
                        value: 1,
                        text: this.$t('variables.details.event') + ' 2',
                        Use: false,
                        Priorite: 0,
                        CritereComparaison: 4,
                        valeurComptage: false,
                        Offset: 0,
                        ArchiveAppear: false,
                        ArchiveDisappear: false,
                        SrcValCriteria: 0,
                        ConstantValue: this.Type == "System.String" ? "" : 0,
                        VariableGroup: null,
                        VariableItem: null,
                    });
                if (this.details?.Event3 != null) this.events.push(this.details?.Event3);
                else
                    this.events.push({
                        value: 2,
                        text: this.$t('variables.details.event') + ' 3',
                        Use: false,
                        Priorite: 0,
                        CritereComparaison: 4,
                        valeurComptage: false,
                        Offset: 0,
                        ArchiveAppear: false,
                        ArchiveDisappear: false,
                        SrcValCriteria: 0,
                        ConstantValue: this.Type == "System.String" ? "" : 0,
                        VariableGroup: null,
                        VariableItem: null,
                    });
                if (this.details?.Event4 != null) this.events.push(this.details?.Event4);
                else
                    this.events.push({
                        value: 3,
                        text: this.$t('variables.details.event') + ' 4',
                        Use: false,
                        Priorite: 0,
                        CritereComparaison: 4,
                        valeurComptage: false,
                        Offset: 0,
                        ArchiveAppear: false,
                        ArchiveDisappear: false,
                        SrcValCriteria: 0,
                        ConstantValue: this.Type == "System.String" ? "" : 0,
                        VariableGroup: null,
                        VariableItem: null,
                    });
                if (this.details?.Event5 != null) this.events.push(this.details?.Event5);
                else
                    this.events.push({
                        value: 4,
                        text: this.$t('variables.details.event') + ' 5',
                        Use: false,
                        Priorite: 0,
                        CritereComparaison: 4,
                        valeurComptage: false,
                        Offset: 0,
                        ArchiveAppear: false,
                        ArchiveDisappear: false,
                        SrcValCriteria: 0,
                        ConstantValue: this.Type == "System.String" ? "" : 0,
                        VariableGroup: null,
                        VariableItem: null,
                    });
                if (this.details?.Event6 != null) this.events.push(this.details?.Event6);
                else
                    this.events.push({
                        value: 5,
                        text: this.$t('variables.details.event') + ' 6',
                        Use: false,
                        Priorite: 0,
                        CritereComparaison: 4,
                        valeurComptage: false,
                        Offset: 0,
                        ArchiveAppear: false,
                        ArchiveDisappear: false,
                        SrcValCriteria: 0,
                        ConstantValue: this.Type == "System.String" ? "" : 0,
                        VariableGroup: null,
                        VariableItem: null,
                    });
                if (this.details?.Event7 != null) this.events.push(this.details?.Event7);
                else
                    this.events.push({
                        value: 6,
                        text: this.$t('variables.details.event') + ' 7',
                        Use: false,
                        Priorite: 0,
                        CritereComparaison: 4,
                        valeurComptage: false,
                        Offset: 0,
                        ArchiveAppear: false,
                        ArchiveDisappear: false,
                        SrcValCriteria: 0,
                        ConstantValue: this.Type == "System.String" ? "" : 0,
                        VariableGroup: null,
                        VariableItem: null,
                    });
                if (this.details?.Event8 != null) this.events.push(this.details?.Event8);
                else
                    this.events.push({
                        value: 7,
                        text: this.$t('variables.details.event') + ' 8',
                        Use: false,
                        Priorite: 0,
                        CritereComparaison: 4,
                        valeurComptage: false,
                        Offset: 0,
                        ArchiveAppear: false,
                        ArchiveDisappear: false,
                        SrcValCriteria: 0,
                        ConstantValue: this.Type == "System.String" ? "" : 0,
                        VariableGroup: null,
                        VariableItem: null,
                    });
                if (this.details?.Event9 != null) this.events.push(this.details?.Event9);
                else
                    this.events.push({
                        value: 8,
                        text: this.$t('variables.details.event') + ' 9',
                        Use: false,
                        Priorite: 0,
                        CritereComparaison: 4,
                        valeurComptage: false,
                        Offset: 0,
                        ArchiveAppear: false,
                        ArchiveDisappear: false,
                        SrcValCriteria: 0,
                        ConstantValue: this.Type == "System.String" ? "" : 0,
                        VariableGroup: null,
                        VariableItem: null,
                    });
                if (this.details?.Event10 != null) this.events.push(this.details?.Event10);
                else
                    this.events.push({
                        value: 9,
                        text: this.$t('variables.details.event') + ' 10',
                        Use: false,
                        Priorite: 0,
                        CritereComparaison: 4,
                        valeurComptage: false,
                        Offset: 0,
                        ArchiveAppear: false,
                        ArchiveDisappear: false,
                        SrcValCriteria: 0,
                        ConstantValue: this.Type == "System.String" ? "" : 0,
                        VariableGroup: null,
                        VariableItem: null,
                    });
                this.events[0].value = 0;
                this.events[0].text = this.$t('variables.details.event') + ' 1';

                this.events[1].value = 1;
                this.events[1].text = this.$t('variables.details.event') + ' 2';

                this.events[2].value = 2;
                this.events[2].text = this.$t('variables.details.event') + ' 3';

                this.events[3].value = 3;
                this.events[3].text = this.$t('variables.details.event') + ' 4';

                this.events[4].value = 4;
                this.events[4].text = this.$t('variables.details.event') + ' 5';

                this.events[5].value = 5;
                this.events[5].text = this.$t('variables.details.event') + ' 6';

                this.events[6].value = 6;
                this.events[6].text = this.$t('variables.details.event') + ' 7';

                this.events[7].value = 7;
                this.events[7].text = this.$t('variables.details.event') + ' 8';

                this.events[8].value = 8;
                this.events[8].text = this.$t('variables.details.event') + ' 9';

                this.events[9].value = 9;
                this.events[9].text = this.$t('variables.details.event') + ' 10';
            }
        },
        async onOk(e) {
            if (!this.$store.state.auth.user.permissions.PermissionWrite) return;
            this.updating = true;

            this.details.Event1 = this.events[0];
            this.details.Event2 = this.events[1];
            this.details.Event3 = this.events[2];
            this.details.Event4 = this.events[3];
            this.details.Event5 = this.events[4];
            this.details.Event6 = this.events[5];
            this.details.Event7 = this.events[6];
            this.details.Event8 = this.events[7];
            this.details.Event9 = this.events[8];
            this.details.Event10 = this.events[9];

            if (this.Group == 'ProdCom') {
                this.details.Formula = this.$refs.editor.getValue();
                this.$refs.editor.cancel();
            }
            const result = await ProdComEquipmentService.setVariableDetails(this.Equipment, this.Project, this.Group, this.Variable, this.details);
            if (result.success == 'n') {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'warning'));
            } else {
                this.$EventBus.$emit('show-toast', new ToastAlert(this.$t('variables.details.saved'), 'info'));
                this.$bvModal.hide(this.PopupId);
                // Refresh treeview
                this.$EventBus.$emit('refresh-treeview-equipments');
                if (this.onChanges != null) this.onChanges(false);
            }
            this.updating = false;
        },
        async onCancel(e) {},
        async getGroupList() {
            const data = await ProdComEquipmentService.getGroups(this.Equipment, this.Project);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                this.triggerGroups = data.ret.List;
            }
        },
        async getCriteriaValueItems() {
            if (this.selectedEvent == null) return;
            let ret = await this.getItems(this.events[this.selectedEvent].VariableGroup, this.events[this.selectedEvent].VariableItem);
            if (ret == null) return;
            this.criteriaItems = ret.result;
            this.VariableItem = ret.var;
        },
        async getItems(groupName, existingItemName) {
            if (groupName == null || groupName == '') return;
            const data = await ProdComEquipmentService.getItems(this.Equipment, groupName, this.Project);
            if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret, data.retParams), 'warning'));
            else {
                let result = [];
                let variableResult = null;
                for (const [key, val] of Object.entries(data.ret.List)) {
                    result.push({ value: val.Name, text: val.Name });
                    if (val.Name == existingItemName) {
                        variableResult = val.Name;
                    }
                }
                return { result: result, var: variableResult };
            }
        },
        async onChangeValueGroup(e) {
            await this.getCriteriaValueItems();
        },
        async onDeleteVariable() {
            let dlg = custom({
                title: this.$t('Delete'),
                messageHtml: `<div style="width: 450px;">${this.$t('confirmDelete')}</div>`, // Make sure the title is fully displayed
                buttons: [
                    {
                        text: this.$t('Ok'),
                        onClick: function () {
                            return true;
                        },
                    },
                    {
                        text: this.$t('Cancel'),
                        onClick: function () {
                            return false;
                        },
                    },
                ],
            });

            var resultDialog = await dlg.show();
            if (resultDialog) {
                let result = await ProdComEquipmentService.deleteInternalVariable(this.Equipment, this.Project, this.Variable);
                if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'warning'));
                else {
                    this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret), 'info'));
                    if (this.onChanges != null) this.onChanges(true);
                }
                this.$bvModal.hide(this.PopupId);
            }
        },
    },
};
</script>