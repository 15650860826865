var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tabs',{class:{ disabled: _vm.loadingConnectionCheck },staticStyle:{"width":"100%"},attrs:{"pills":"","card":"","h-100":"","d-flex":"","flex-column":""}},[_c('b-tab',{staticClass:"pb-0",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"tabHeaderText"},[_vm._v(" "+_vm._s(_vm.$t('Connection'))+" "),(_vm.invalidState)?_c('font-awesome-icon',{staticClass:"ml-1 text-danger",attrs:{"icon":"fa fa-warning"}}):_vm._e()],1)]},proxy:true}])},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"7"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.Patlite.address') + ' *',"label-for":"address"}},[_c('b-form-input',{class:{ disabled: !_vm.$store.state.auth.user.permissions.PermissionWrite },attrs:{"id":"address","state":_vm.addressState},on:{"change":(value) => {
                                        if (value == null || value == '') {
                                            _vm.address = 'localhost';
                                        }
                                        _vm.isConnected = false;
                                        _vm.$refs.dataGrid.instance.deselectAll();
                                        _vm.columnsFound = [];
                                    }},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1)],1),_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label-for":"checkConnectionButton"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"blank-label"},[_vm._v(" ")])]},proxy:true}])},[_c('b-button',{staticClass:"btn-block",class:{ disabled: !_vm.$store.state.auth.user.permissions.PermissionRead || _vm.loadingConnectionCheck },attrs:{"id":"checkConnectionButton"},on:{"click":_vm.onCheckConnection}},[_vm._v(" "+_vm._s(_vm.$t('equipment.Patlite.checkConnection'))+" "),(_vm.loadingConnectionCheck)?_c('font-awesome-icon',{staticClass:"fa-lg fa-spin-custom",attrs:{"icon":"fas fa-spinner-third"}}):_vm._e()],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"7"}},[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.Patlite.discoverTime') + ' *',"label-for":"discover"}},[_c('b-form-input',{class:{ disabled: !_vm.$store.state.auth.user.permissions.PermissionWrite || !_vm.isConnected },attrs:{"id":"discover","type":"number","min":"10","max":"1800","state":_vm.discoverTime},on:{"change":(value) => {
                                            if (value == null || value == '') {
                                                _vm.discover = 300;
                                            } else if (value > 1800) {
                                                _vm.discover = 1800;
                                            } else if (value <= 0) {
                                                _vm.discover = 10;
                                            }
                                        }},model:{value:(_vm.discover),callback:function ($$v) {_vm.discover=$$v},expression:"discover"}})],1)],1),_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label-for":"discoveryButton"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"blank-label"},[_vm._v(" ")])]},proxy:true}])},[_c('b-button',{staticClass:"btn-block",class:{ disabled: !_vm.$store.state.auth.user.permissions.PermissionRead || _vm.loadingDiscovery || !_vm.isConnected },attrs:{"id":"discoveryButton"},on:{"click":_vm.onDiscovery}},[_vm._v(" "+_vm._s(_vm.$t('equipment.Patlite.discovery'))+" "),(_vm.loadingDiscovery)?_c('font-awesome-icon',{staticClass:"fa-lg fa-spin-custom",attrs:{"icon":"fas fa-spinner-third"}}):_vm._e()],1)],1)],1)],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('equipment.Patlite.columns') + ' (' + _vm.columnsCount +')',"label-for":"columnsFound"}},[_c('DxDataGrid',{ref:"dataGrid",style:(_vm.gridStyle),attrs:{"id":"columnsFound","keyExpr":"Id","dataSource":_vm.columnsFound,"row-alternation-enabled":true,"paging":{ enabled: false },"editing":{ mode: 'cell', allowUpdating: true }},on:{"content-ready":_vm.onContentReady,"row-updating":_vm.checkDuplicateNames,"init-new-row":_vm.onInitNewRow}},[_c('DxSelection',{attrs:{"select-all-mode":'page',"show-check-boxes-mode":'onClick',"mode":"multiple"}}),_c('DxColumn',{attrs:{"dataField":"Id","caption":_vm.$t('Id'),"allow-editing":false,"visible":false}}),_c('DxColumn',{attrs:{"dataField":"Name","caption":_vm.$t('Name'),"allow-editing":true,"validation-rules":[{ type: 'required' }]}}),_c('DxColumn',{attrs:{"dataField":"Address","caption":_vm.$t('Address'),"allow-editing":true}})],1)],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }